@import 'react-redux-toastr/src/styles/index';
@import './../styles/mixins.scss';

//global styles
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

ol,
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}

body {
  font-size: 14px;
  font-family: 'Arial', sans-serif;
  background: #fafafa;
}

.contentHeader {
  border: 1px solid #98aab1;
  border-bottom: none;
  background: #e6e6e6;
  padding: 8px 20px 7px;
  font-size: 13px;
  @include max-screen-980 {
    padding: 6px 10px 5px;
  }
}

.content {
  padding: 20px;
  border: 1px solid #98aab1;
  background: white;
  margin-bottom: 20px;
  @include max-screen-980 {
    padding: 10px;
  }
  &__txt {
    font-size: 13px;
    color: #575757;
  }
  &__txtItem {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.sideBlock {
  position: relative;
  margin-bottom: 20px;
  font-size: 13px;
  &_slider {
    @include max-screen-768 {
      margin-bottom: 40px;
    }
  }
  &__title {
    border: 1px solid #98aab1;
    background: #e6e6e6;
    padding: 8px 10px 7px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    @include max-screen-768 {
      margin-bottom: 0;
      border-bottom: none;
    }
    @include max-screen-530 {
      padding: 6px 10px 5px;
    }
  }
  &__titleTxt {
    font-weight: bold;
    color: #000;
  }
  &__titleLink {
    color: #575757;
    text-decoration: underline;
    &:hover {
      color: #000;
    }
  }
  &__content {
    border: 1px solid #98aab1;
    background: #fff;
    padding: 10px;
    letter-spacing: -0.2px;
    &_slider {
      padding: 0;
      background: #f4f4f4;
      @include max-screen-768 {
        padding: 10px 0;
      }
    }
    &_tops {
      @include max-screen-530 {
        display: flex;
      }
      > a:first-child {
        @include max-screen-530 {
          margin: 0 20px 0 0;
        }
      }
    }
    &_card {
      @include max-screen-530 {
        display: flex;
      }
      .sideBlock__imgWrap {
        @include max-screen-530 {
          width: auto;
          padding: 0;
          border: none;
          margin: 0 10px 0 0;
        }
      }
    }
    &_author {
      @include max-screen-530 {
        display: flex;
        align-items: center;
      }
    }
  }
  &__link {
    display: block;
    color: #575757;
    margin-bottom: 10px;
    &::first-letter {
      text-transform: uppercase;
    }
    &:last-child {
      margin-bottom: 0;
    }
    &:hover {
      color: #000;
    }
    &_inlineBlock {
      display: inline-block;
      margin-bottom: 5px;
    }
  }
  &__img {
    display: block;
    position: relative;
    width: 100%;
    height: auto;
    color: transparent;
    &_slider {
      position: relative;
      z-index: 1;
    }
    &_author {
      width: 131px;
      height: auto;
      margin: 12px auto 10px;
      background-color: #fafafa;
      min-height: 60px;
      &:before {
        content: ' ';
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 30px;
        height: 40px;
        background: url('/icons/book-no-cover.svg') no-repeat 50%;
        background-size: cover;
        z-index: 0;
      }
      @include max-screen-530 {
        width: 83px;
        margin: 0 10px 0 0;
      }
      > img {
        display: block;
        width: 100%;
        min-width: 83px;
        height: auto;
        position: relative;
        z-index: 1;
      }
    }
  }
  &__imgWrap {
    display: block;
    width: 100%;
    height: auto;
    padding: 10px;
    border: 1px solid #e6e6e6;
    background-color: #fafafa;
    margin-bottom: 10px;
  }
  &__imgBookWrap {
    display: block;
    position: relative;
    width: 83px;
    min-height: 70px;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
    margin: 0 auto;
    &:before {
      content: ' ';
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 30px;
      height: 40px;
      background: url('/icons/book-no-cover.svg') no-repeat 50%;
      background-size: cover;
      z-index: 0;
    }
  }
  &__imgBook {
    display: block;
    width: 100%;
    height: auto;
    color: transparent;
    position: relative;
    z-index: 1;
  }
  &__heading {
    display: block;
    font-size: 14px;
    font-weight: bold;
    line-height: 18px;
    margin-bottom: 6px;
    transition: color 0.3s ease;
    &:hover {
      color: #00ccff;
    }
  }
  &__author {
    font-weight: bold;
    display: block;
    color: #575757;
    margin-bottom: 4px;
    transition: color 0.3s ease;
    &:hover {
      color: #000;
    }
    &_noLink {
      &:hover {
        color: #575757;
      }
    }
  }
  &__txt {
    line-height: 16px;
    color: #575757;
    &_block {
      display: block;
    }
  }
  &__slider {
    position: relative;
    @include max-screen-768 {
      width: 165px;
      height: auto;
      margin: 0 auto;
    }
  }
  &__slide {
    width: 100%;
    height: 100%;
    position: relative;
  }
  &__slideDescription {
    position: absolute;
    width: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    display: flex;
    flex-direction: column;
    padding: 0 10px;
    padding-top: 100px;
    z-index: 0;
    color: transparent;
    @include max-screen-768 {
      padding-top: 70px;
    }
    &:before {
      content: ' ';
      position: absolute;
      left: 50%;
      top: 20%;
      transform: translate(-50%, -50%);
      width: 70px;
      height: 100px;
      background: url('/icons/book-no-cover.svg') no-repeat 50%;
      background-size: cover;
      z-index: 0;
      @include max-screen-768 {
        width: 35px;
        height: 50px;
      }
    }
  }
  &__slideTitle {
    display: inline-block;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: bold;
    color: #1a5f9b;
    @include max-screen-768 {
      font-size: 14px;
    }
    &:hover {
      color: #1f73bc;
    }
  }
  &__slideAuthor {
    font-size: 14px;
    color: #868686;
    @include max-screen-1060 {
      font-size: 12px;
    }
  }
  &__sliderControls {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 24px;
    border: 1px solid #98aab1;
    background: white;
    @include max-screen-768 {
      bottom: -20px;
    }
  }
}

//Cлайдер в блоке отзывы
#__next #__newsSlider {
  .slick-prev,
  .slick-next {
    top: 50%;
  }
  .slick-slide {
    width: 100%;
    margin: 0;
  }
  .slick-track {
    display: flex !important;
    align-items: center !important;
  }
  .slick-list {
    width: auto;
  }
  .slick-dots {
    bottom: 2px;
    z-index: 1;
    @include max-screen-768 {
      bottom: -28px;
    }
  }

  .slick-dots li {
    width: 8px;
    height: 8px;
    margin: 0 6px;
  }

  .slick-dots li button:before {
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #575757;
  }
}

// Cлайдер другие книги автора
#__next #__otherAuthorsBooksSlider {
  position: relative;
  .slick-initialized {
    opacity: 0;
    animation: fadeIn ease-in 1;
    animation-duration: 0.4s;
    animation-fill-mode: forwards;
    overflow: hidden;
    position: static;
  }
  .slick-slide {
    width: min-content;
    width: -moz-min-content;
    margin: 0 5px;
  }
  .slick-next,
  .slick-prev {
    position: absolute;
    display: block;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    cursor: pointer;
    z-index: 99;
    line-height: 0;
    font-size: 0;
    padding: 0;
    border: none;
    top: 130px;
    background: #fafafa;
    box-shadow: 0 4px 3px rgba(0, 0, 0, 0.25);
    color: transparent;
  }
  .slick-prev {
    left: -12px;
  }
  .slick-next {
    right: -12px;
  }
  .slick-prev:after,
  .slick-next:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 52%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    width: 6px;
    height: 11px;
    background: url('/icons/arrow-slider-black.svg') no-repeat 0 0;
    background-size: cover;
  }
  .slick-prev:after {
    left: 48%;
    transform: translate(-50%, -50%) scale(-1, 1);
  }
  .slick-prev:before,
  .slick-next:before {
    color: transparent;
  }
  .slick-list {
    width: max-content;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}

.link {
  color: #035297;
  text-decoration: none;
  outline: none;
  transition: color 0.3s ease 0s;
  &:hover {
    text-decoration: underline;
  }
}

.breadcrumbsList {
  font-size: 13px;
  line-height: 1.2;
  list-style-type: none;
  &__item {
    position: relative;
    display: inline-block;
    color: #727272;
    padding-right: 17px;
    &:after {
      content: '>';
      position: absolute;
      bottom: 0;
      right: 4px;
    }
    &:last-child {
      padding-right: 0;
      &:after {
        display: none;
      }
    }
  }
  &__itemLink {
    display: inline-block;
    color: #000;
    transition: color 0.3s ease;
    font-weight: bold;
    transition: color 0.3s ease;
    &:hover {
      color: #00ccff;
    }
    &_genre {
      margin-left: 4px;
      &::first-letter {
        text-transform: uppercase;
      }
    }
  }
}

.pageTitle {
  font-size: 16px;
  margin-bottom: 16px;
  font-weight: bold;
  line-height: 1;
  @include max-screen-530 {
    font-size: 14px;
    margin-bottom: 14px;
  }
}

.pageBlock {
  margin-bottom: 20px;
}

.pageLink {
  color: #1a5f9b;
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
}

.allBooksLink {
  font-size: 14px;
  font-weight: bold;
  text-decoration: underline;
  margin-left: 10px;
  display: block;
  &:hover {
    text-decoration: none;
  }
  @include max-screen-980 {
    margin: 10px 0 0 0;
  }
  &_top {
    margin: 10px 0 0 10px;
    @include max-screen-530 {
      margin: 10px 0 0 0;
    }
  }
}

.topBooksLinks {
  display: flex;
  justify-content: space-between;
  &__openList {
    display: none;
    position: relative;
    margin-top: 10px;
    padding-right: 14px;
    cursor: pointer;
    color: #000;
    font-weight: bold;
    text-decoration: underline;
    @include max-screen-530 {
      display: block;
    }
    &:hover {
      text-decoration: none;
    }
    &:before {
      content: '';
      position: absolute;
      right: 0;
      top: 6px;
      width: 9px;
      height: 9px;
      background: url('/icons/arrow-down-icon.svg') no-repeat 0 0;
      background-size: contain;
    }
  }
}

.litresWidget {
  &__title {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 5px;
    padding: 0;
  }
}

.mainBtn {
  display: inline-block;
  text-align: center;
  outline: none;
  border: none;
  cursor: pointer;
  user-select: none;
  border-radius: 3px;
  padding: 8px 16px;
  font-size: 14px;
  text-decoration: none;
  transition: all 0.3s ease;
  position: relative;
  background: #1a5f9b;
  color: #fff;
  &:hover {
    background: #1f73bc;
    color: #fff;
  }
  &_fullWidth {
    width: 100%;
  }
}

.title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 8px;
}

//Cписок
.itemsList {
  margin-bottom: 20px;
}

//Cтилизация аудиоплеера
#audio_player {
  .rhap_additional-controls {
    display: none;
  }
  .rhap_time {
    font-size: 14px;
    color: #575757;
  }
  .rhap_container {
    padding: 10px 10px;
  }
}

.clearfix::after {
  content: '';
  clear: both;
  display: block;
}

//masonry search//
.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  flex-wrap: wrap;
  margin-left: -12px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 12px; /* gutter size */
  background-clip: padding-box;
}
